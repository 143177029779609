<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-card class="bg-transparent mb-6">
        <v-row class="pa-0 ma-0">
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/questmanagement/quests').catch(() => {})">Quests</h2>
          <h2 class="text-h3 mr-4 text-light font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/questmanagement/questlines').catch(() => {})">Questreihen</h2>
          <h2 class="text-h3 text-black font-weight-bolder mb-0 cursor-pointer" @click="$router.push('/questmanagement/tours').catch(() => {})">Erkundungstouren</h2>
        </v-row>
       
      </v-card>
      <v-row class="mt-8">
        <v-col cols="12">
          <datatable-search></datatable-search>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DatatableSearch from "./Widgets/DatatableSearch.vue";

export default {
  name: "datatables",
  components: {
    DatatableSearch,
  },
};
</script>
